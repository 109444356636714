<template>
  <div>
    <a-card title="费用合计">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker v-model="dateRange" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="客户" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" :md="8" :xl="8" style="margin-bottom: 12px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
          </a-button-group>
        </a-col>
      </a-row>
      <a-row gutter="16">
        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="items"
            :pagination="pagination"
            :scroll="{ y: 480 }"
            :loading="loading"
            @change="onChangeTable"
          >
          </a-table>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>
<script>
import { exportExcel } from "@/utils/excel";
import { chargeSumExport } from "@/api/export";
import moment from "moment";
import { chargeSumList } from "@/api/charging";

export default {
  name: "ChargeSum",
  data() {
    return {
      loading: false,
      dateRange: [moment().add(-7, "days").startOf("day"), moment().startOf("day")],
      searchForm: {
        page: 1,
        page_size: 48,
        start_date: moment().add(-7, "days").startOf("day").format("YYYY-MM-DD"),
        end_date: moment().add(1, "days").startOf("day").format("YYYY-MM-DD"),
      },
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      totalAmount: 0,
      items: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "客户",
          dataIndex: "client_name",
          key: "client_name",
        },
        {
          title: "入库配送费用(元)",
          dataIndex: "stock_in_transport_amount",
          key: "stock_in_transport_amount",
        },
        {
          title: "入库卸货费用(元)",
          dataIndex: "stock_in_handling_amount",
          key: "stock_in_handling_amount",
        },
        {
          title: "入库其他费用(元)",
          dataIndex: "stock_in_charge_amount",
          key: "stock_in_charge_amount",
        },
        {
          title: "出库配送费用(元)",
          dataIndex: "stock_out_transport_amount",
          key: "stock_out_transport_amount",
        },
        {
          title: "出库装车费用(元)",
          dataIndex: "stock_out_handling_amount",
          key: "stock_out_handling_amount",
        },
        {
          title: "出库其他费用(元)",
          dataIndex: "stock_out_charge_amount",
          key: "stock_out_charge_amount",
        },
        {
          title: "仓储费用(元)",
          dataIndex: "settlement_stock_amount",
          key: "settlement_stock_amount",
        },
        {
          title: "总金额(元)",
          dataIndex: "total_amount",
          key: "total_amount",
        },
      ],
    };
  },
  components: {},
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    moment,
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();
    },
    handelAdd() {
      this.$refs.modal_add.show();
    },
    onChangePicker(date, dateString) {
      this.dateRange = date;
      let startDate = date[0].clone(),
        endDate = date[1].clone();
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      chargeSumList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.selectedRowKeys = [];
          this.loading = false;
        });
    },
    exportExcel() {
      chargeSumExport({
        start_date: this.searchForm.start_date,
        end_date: this.searchForm.end_date,
      })
        .then((resp) => {
          exportExcel(resp, "费用合计列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
